/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import styled from 'styled-components';
import { audiLightTheme, audiDarkTheme, SpacingLabel, ThemeProvider } from '@audi/audi-ui-react';

import { AppContent } from '../../types';
import { useTracking } from '../../utils/useTracking';

const StyledSpacer = styled.div<{
  spacing: SpacingLabel;
  spacingFactor: number;
}>`
  ${(props) => {
    // only use css calc function if spacingFactor is not 1
    return props.spacingFactor === 1
      ? `
        height: var(${props.theme.responsive?.spacing[props.spacing]});
      `
      : `
        height: calc(${props.spacingFactor} * var(${props.theme.responsive?.spacing[props.spacing]}));
      `;
  }};

  ${(props) => `background-color: var(${props.theme.colors.background.level[0]});`};
`;

export const Spacer: React.FC<AppContent> = (props) => {
  const { space, theme } = props;

  const ref = React.useRef(null);
  const tracking = useTracking(ref, 'spacer-fa', {
    implementer: 37,
  });

  React.useEffect(() => {
    tracking.sendReadyEvent();
    tracking.registerImpressionTracking();
  }, []);

  // 2xxxl is not a valid SpacingLabel and results in a calculation of double xxxl spacing
  const spacingFactor = space === '2xxxl' ? 2 : 1;
  const spacing: SpacingLabel = space !== '2xxxl' ? space : 'xxxl';

  return (
    <ThemeProvider theme={theme === 'Dark' ? audiDarkTheme : audiLightTheme}>
      <StyledSpacer ref={ref} data-test-id="spacer" spacing={spacing} spacingFactor={spacingFactor}>
        &nbsp;
      </StyledSpacer>
    </ThemeProvider>
  );
};
