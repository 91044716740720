import React, { createContext, PropsWithChildren, useMemo } from 'react';

import { Logger } from '@feature-hub/logger';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';

interface FeatureAppEnv {
  featureAppId: string;
  featureAppName?: string;
}

interface ContextState {
  readonly featureAppEnv: FeatureAppEnv;
  readonly loggerService?: Logger;
  readonly trackingService?: TrackingServiceV2;
}

export const Context = createContext<ContextState>({} as ContextState);

export interface ContextProviderProps {
  readonly featureAppEnv: FeatureAppEnv;
  readonly loggerService?: Logger;
  readonly trackingService?: TrackingServiceV2;
}

const ContextProvider = ({
  children,
  featureAppEnv,
  loggerService: logger,
  trackingService,
}: PropsWithChildren<ContextProviderProps>) => {
  const value = useMemo(() => {
    return {
      featureAppEnv,
      loggerService: logger,
      trackingService,
    };
  }, []);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default ContextProvider;
