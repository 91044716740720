/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';

import { ContentServiceV1 } from '@oneaudi/content-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';

import App from './FeatureApp';
import ContextProvider from './Context';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
  readonly 'audi-tracking-service'?: TrackingServiceV2;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'audi-content-service': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
    },
    externals: {
      '@audi/audi-ui-react': '^3.4.1',
    },
  },
  optionalDependencies: {
    featureServices: {
      'audi-tracking-service': '^2.0.0',
    },
  },

  create: ({
    featureServices,
    featureAppId,
    featureAppName,
  }: FeatureAppEnvironment<FeatureServiceDependencies, void>) => {
    const loggerService = featureServices['s2:logger'];
    loggerService?.info('Feature App created.');

    const contentService = featureServices['audi-content-service'];

    const trackingService = featureServices['audi-tracking-service'];
    if (trackingService) {
      trackingService.featureAppName = __FEATURE_APP_NAME__;
    }

    return {
      render: () => {
        return (
          <ContextProvider
            featureAppEnv={{ featureAppId, featureAppName }}
            loggerService={loggerService}
            trackingService={trackingService}
          >
            <App contentService={contentService} />
          </ContextProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;
